var sso = sso || {};

/**
 * Creates the given namespace if it does not already exist.  For example: 
 *   rs.provide("foo.bar.baz");
 *   
 * is equivalent to:
 *   foo = foo || {};
 *   foo.bar = foo.bar || {};
 *   foo.bar.baz = foo.bar.baz || {};
 * 
 * So the following code would execute safely:
 *   rs.provide("foo.bar.baz");
 *   foo.bar.baz.count = 0;
 * 
 * @param namespace The namespace to be created.
 */
// ReSharper disable InconsistentNaming
sso.provide = function (namespace) {
    var object = window;
    $.each(namespace.split('.'), function (i, namespacePart) {
        object[namespacePart] = object[namespacePart] || {};
        object = object[namespacePart];
    });
};

sso.cookie = (function () {
    function _get(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        var c;
        for (var i = 0; i < ca.length; i++) {
            c = ca[i].replace(/^\s+|\s+$/g, '');
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return null;
    }

    function _set(cname, cvalue, cexpires) {
        document.cookie = cname + "=" + cvalue + "; expires=" + cexpires + "; path=/";
    }

    function _delete(cname) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    }

    function _constructExpiresFromDate(date) {
        return date.toDateString().split(' ')[0] + ', ' +
            date.toDateString().split(' ')[2] + ' ' +
            date.toDateString().split(' ')[1] + ' ' +
            date.getUTCFullYear() + ' ' +
            date.getUTCHours() + ':' +
            date.getUTCMinutes() + ':' +
            date.getUTCSeconds() + ' UTC';
    }

    return {
        get: _get,
        set: _set,
        remove: _delete,
        constructExpires: _constructExpiresFromDate
    };
}());

/**
 * Set up the logout callback.
 */
sso.redirectCountdown = function () {
    $(function () {
        var redirectIn = $('.countdown').html(),
            redirectUri = $('#logoutBody').data('redirect'),
            timeout;

        timeout = setInterval(function () {
            redirectIn--;
            $('.countdown').html(redirectIn);

            if (redirectIn == 0) {
                clearInterval(timeout);
                window.location = redirectUri;
            }
        }, 1000);
    });
};
/**
 * Set up the redirect home callback.
 */
sso.redirectHomeCountdown = function () {
    $(function () {
        var redirectIn = $('.countdown').html(),
            timeout;

        timeout = setInterval(function () {
            redirectIn--;
            $('.countdown').html(redirectIn);

            if (redirectIn == 0) {
                clearInterval(timeout);
                window.location.replace(window.location.origin);
            }
        }, 1000);
    });
};
/**
 * Finally remove any cookies that might still be lingering.
 */
sso.logout = function () {
    var nextCheckCookieName = $("#pageJs").data("data");
    sso.cookie.remove(nextCheckCookieName);

    // Call redirectCountdown when done.
    sso.redirectCountdown();
};
/**
 * Create the kendoWindow for the about dialog.
 */
sso.attachAboutControls = function () {
    $('#about').kendoWindow({
        draggable: true,
        modal: true,
        resizable: false,
        actions: [
            "Close"
        ],
        visible: false
    });
};
/**
 * Show the About dialog
 */
sso.showAboutDialog = function () {
    $('#about').data('kendoWindow').center().open();
};
/**
 * Attach the culture dropdown controls.
 */
//sso.attachCultureDropdownControls = function () {
//    $("#culture").msDropDown({
//        useSprite: 'smallIcons'
//    });
//};
sso.createAccountHelpPopup = function() {
    var popup = $('#AccountCreateInfoWindow').kendoWindow({
        draggable: true,
        modal: true,
        resizable: false,
        visible: false
    });
    $('#createAccountInfo').on('click', function () {
        popup.show().data("kendoWindow").center().open();
    });
};
/**
 * Set up the header controls drop-down object
 */
sso.headerControls = (function () {

    var _shouldShow;

    function _hideDropDown() {
        if (!_shouldShow) {
            $('#header-dropdown').hide();
        }
    }

    function _attachNavBarMouseoverControls() {
        $('#navbar-avatar').on('mouseover', function () {
            _shouldShow = true;
            $('#header-dropdown').show();
        });

        $('#navbar-avatar').on('mouseleave', function () {
            _shouldShow = false;
            setTimeout(_hideDropDown, 500);
        });

        $('#header-dropdown').on('mouseover', function () {
            _shouldShow = true;
            $('#header-dropdown').show();
        });

        $('#header-dropdown').on('mouseleave', function () {
            _shouldShow = false;
            setTimeout(_hideDropDown, 500);
        });
    }

    function init() {
    }

    init();

    return {
        attachNavBarMouseoverControls: _attachNavBarMouseoverControls
    };
}());

sso.culture = (function () {
    var _cultureId = '#culture',
        _sURLVariables = window.location.search.substring(1).split('&'),
        _appId = _GetQueryStringParams('appId'),
        _toRelationId = _GetQueryStringParams('toRelationId'),
        _locale = _GetQueryStringParams('locale'),
        _registrationSource = _GetQueryStringParams('registrationSource'),
        _firstname = _GetQueryStringParams('firstname'),
        _lastname = _GetQueryStringParams('lastname'),
        _email = _GetQueryStringParams('email');

    function _GetQueryStringParams(params) {
        for (var i = 0; i < _sURLVariables.length; i++) {
            var _sParameterName = _sURLVariables[i].split('=');
            if (_sParameterName[0].toLowerCase() == params.toLowerCase()) {
                return _sParameterName[1];
            }
        }
        return null;
    }

    function _IsSet(val) {
        return (val !== null && val !== undefined);
    }

    function _redirect() {
        var _location = '/Culture?culture=' + $(_cultureId).val();

        if (_IsSet(_appId)) {
            _location += '&appId=' + _appId;
        }
        if (_IsSet(_toRelationId)) {
            _location += '&toRelationId=' + _toRelationId;
        }
        if (_IsSet(_locale)) {
            _location += '&locale=' + _locale;
        }
        if (_IsSet(_registrationSource)) {
            _location += '&registrationSource=' + _registrationSource;
        }
        if (_IsSet(_firstname)) {
            _location += '&firstname=' + _firstname;
        }
        if (_IsSet(_lastname)) {
            _location += '&lastname=' + _lastname;
        }
        if (_IsSet(_email)) {
            _location += '&email=' + _email;
        }

        window.location = _location;
    }

    function _attachRedirectControls() {
        // Change the culture.
        $(_cultureId).on('change', sso.culture.redirect);
    }

    return {
        redirect: _redirect,
        attachRedirectControls: _attachRedirectControls
    };
}());

$(document).ready(function () {

    // Setup the header drop-down controls.
    sso.headerControls.attachNavBarMouseoverControls();
    sso.attachAboutControls();
    //sso.attachCultureDropdownControls();
    sso.createAccountHelpPopup();
    
    sso.culture.attachRedirectControls();

	$('.about').on('click', sso.showAboutDialog);

	//*** Show/Hide Password

	$(".show-hide-pass").on("click", function (e) {
		e.preventDefault();
		var accountField = $("#Password"),
			showHideSelector = $(e.currentTarget);
		(e.currentTarget.id === "show-pass") ? accountField.attr('type', 'text') : accountField.attr('type', 'password');
		$(".show-hide-pass.hide").removeClass('hide');
		$(showHideSelector).addClass('hide');
	});
    
    //*** Locale Selector
    $("#closeLocalSel").click(function () {
        $("#localeSel").click();
    });


    $("#localeSel").click(function (e) {
        $(this).toggleClass("selected");
        if ($(".icon-arrow-down-1", this).length) $(".icon-arrow-down-1", this).toggleClass("icon-arrow-up-1");
        else $(".icon-arrow-up-1", this).toggleClass("icon-arrow-down-1");

        if ($(".locale-selector").is(":visible")) $(".locale-selector").slideUp(400);
        else $(".locale-selector").slideDown(400);

        e.stopImmediatePropagation();
    });

    var localeSelectorChangeHandler = function (e) {
        var sender = e.target;
        var locale = sender.options[sender.selectedIndex].value;
        var qs = $('#QueryString').val();

        if (locale && locale.length == 5) {
            var original = (qs && qs.length > 0 ? encodeURIComponent(decodeURIComponent(qs)) : '');
            window.location.href = '/Scripts/LocaleChange.ashx?locale=' + locale + '&original=' + original;
        }
    };

    if ($("select.localeSelector")) {
        $("select.localeSelector").kendoDropDownList({
            height: 500
        });
        $("select.localeSelector").change(localeSelectorChangeHandler);
    }

    //Set up links that depend on querystring
    $('a[data-qsdependant="true"]').each(function() {
        var preQs = $(this).attr('data-preqs');
        var qs = $('#QueryString');
        if (preQs && qs && qs.val()) {
            $(this).attr('href', preQs + '?' + qs.val());
        }
    });
           
    if (!String.prototype.format) {
        String.prototype.format = function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                  ? args[number]
                  : match
                ;
            });
        };
    }


    var qs = $('#QueryString');
    $('#hlHeader').on('click', function () { window.location = '/' + qs.val() > 0 ? qs : ''; }).css('cursor', 'pointer');


    //Start caps monitoring
    $(window).capslockstate();

 
    var fieldsToMonitor = [$('#Password'),
						   $('#Confirm'),
						   $('#create-password'),
						   $('#confirm-password'),
                           $('input[name="SecurityQuestions[0].Answer"]'),
                           $('input[name="SecurityQuestions[1].Answer"]'),
                           $('input[name="SecurityQuestions[2].Answer"]'),
                           $('#AnswerInput')];

    //Create caps warning tooltips and events
    $.each(fieldsToMonitor, function (i, v) {
        v.attr('title', $('#Password_CapsLock').text())
            .tipso({ background: 'tomato', position: 'right' })
            .on('blur', function () { $(this).tipso('hide'); })
            .on('focus', function () { $(this).tipso($(window).capslockstate('state') === true ? 'show' : 'hide'); });
    });


    $(window).bind('capsOff', function () {
        $.each(fieldsToMonitor, function (i, v) {
            if(v)
                v.tipso('hide');
        });
    });

    $(window).bind('capsOn', function () {
        $.each(fieldsToMonitor, function (i, v) {
            if (v.is(':focus'))
                v.tipso('show');
        });
    });

    //Legal
    $('#relStories').remove();
    $('#titleHeader').remove();
    $('#breadcrumbContainer').remove();
    
    var privacyPolicypopup = $('#PrivacyPolicyPopup').kendoWindow({
        draggable: true,
        modal: true,
        resizable: false,
        visible: false,
        height: 620,
        width: 550
    });

    var termsOfUSePopup = $('#TermsOfUsePopup').kendoWindow({
        draggable: true,
        modal: true,
        resizable: false,
        visible: false,
        height: 620,
        width: 550 
    }); 
       
    $('#legal-links a:first-child').on('click', function () { termsOfUSePopup.show().data("kendoWindow").center().open(); });
    $('#legal-links a:nth-child(2)').on('click', function () { privacyPolicypopup.show().data("kendoWindow").center().open(); });

    $('#termsOfUse_Layout').on('click', function () { termsOfUSePopup.show().data("kendoWindow").center().open(); });
    $('#privacyPolicy_Layout').on('click', function () { privacyPolicypopup.show().data("kendoWindow").center().open(); });
    
    $('.privacy-policy article').removeClass('col8');
    var tou = $('.terms-of-use article').removeClass('col8');
    $('.terms-of-use').html(tou);  
     
    //Avoid multiple login form submission
    $(document).on('invalid-form.validate', 'form[name="LoginForm"]', function () {
        $(this).find('input[type="submit"]').removeAttr('disabled');
    });
    $(document).on('submit', 'form[name="LoginForm"]', function () {
        $(this).find('input[type="submit"]').attr('disabled', 'disabled');
    });

    //disable create account link
    $(".createAccountLink").click(function () {
        $(this).attr("disabled", "disabled");
    });
    
    
    //IE9 Header text
    var $header = $("#loginHeader").html();
    $("#loginHeader").attr("data-innertext", $header);

    var $subheader = $("#loginSubHeader").html();
    $("#loginSubHeader").attr("data-innertext", $subheader);


});